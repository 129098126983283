import { Link } from "gatsby";
import React from "react";
import "../styles/menu.scss";

export default function Menu() {
  return (
    <header>
      <h1>
        <Link to="/">Kitárul a világ</Link>
      </h1>
      <div className="menu-wrap">
        <input type="checkbox" id="toggle" aria-label="menu-tool" />
        <div className="burger">
          <div></div>
        </div>
        <nav>
          <ul>
            <div>
              <li>
                <Link to="/">Főoldal</Link>
              </li>
              <li>
                <Link to="/about">Rólunk</Link>
              </li>
              <li>
                <Link to="/projects">Pályázataink</Link>
              </li>
              <li>
                <Link to="/coop">Együttműködések</Link>
              </li>
            </div>
          </ul>
        </nav>
      </div>
    </header>
  );
}
