import React, { useEffect } from "react";
import gsap from "gsap";
import { TimelineMax } from "gsap/gsap-core";

export default function AnimationWrapper({ children }) {
  const tl = new TimelineMax();

  useEffect(() => {
    tl.from(".glass", {
      x: -100,
      opacity: 0,
      duration: 1.5,
      delay: 0.5,
      ease: "slow",
      backdropFilter: "blur(0px)",
    })
      .from(
        ".btn",
        {
          x: -100,
          opacity: 0,
          duration: 1.5,
          ease: "slow",
        },
        "-=1"
      )
      .from(
        "#h1-animate",
        {
          y: -20,
          clipPath: "inset(0 0 100% 0)",
          duration: 1,
          ease: "power2",
        },
        "-=1"
      )
      .from(
        ".quote-main",
        {
          y: -20,
          clipPath: "inset(0 0 100% 0)",
          duration: 1.5,
          ease: "power1",
        },
        "-=1"
      );

    if (window.innerWidth < 1030) {
      gsap.from(".activities-first", {
        scrollTrigger: ".on-scroll-1",
        x: -200,
        opacity: 0,
        ease: "power1",
        duration: 2,
        stagger: 0.3,
        delay: 1,
      });

      gsap.from(".activities-second", {
        scrollTrigger: ".on-scroll-2",
        x: 200,
        opacity: 0,
        ease: "power1",
        duration: 2,
        stagger: 0.3,
        delay: 1,
      });
    } else {
      gsap.from(".activity-section", {
        scrollTrigger: ".on-scroll-1",
        y: -20,
        opacity: 0,
        duration: 2,
        stagger: 0.3,
        delay: 1,
        ease: "power1",
      });
    }

    gsap.from(".contact-card", {
      scrollTrigger: ".content-inner",
      y: 500,
      opacity: 0,
      duration: 2,
      stagger: 0.5,
      delay: 1,
      ease: "power2",
    });

    gsap.from("#contact-info", {
      scrollTrigger: ".contact-card",
      y: 500,
      opacity: 0,
      duration: 2,
      delay: 1,
      ease: "power2",
    });

    gsap.from(".subp-animate", {
      x: 50,
      opacity: 0,
      duration: 0.6,
      ease: "slow",
      stagger: 0.2,
    });
  });
  return <>{children}</>;
}
